<template>
  <div>
    <div>
      <b-card no-body class="">
        <!-- <b-card-text class="px-5">
            <div class="d-flex justify-content-between align-items-center" >
              <h2> Congés et absences</h2>

              <b-btn class="m-md-2" @click="exportToPdf">Exporter en pdf</b-btn> 

              <b-dropdown id="dropdown-1" text="Exporter" class="m-md-2">
                <b-dropdown-item @click="exportToPdf"> PDF </b-dropdown-item>
                <b-dropdown-item>EXCEL </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-card-text> -->

        <b-card-text>
          <div class="d-flex justify-content-between p-2">
            <div class="c_ol-md-6">
              <h2>Congés et absences</h2>
            </div>
            <div class="c_ol-md-4">
              <b-form-group
                label-cols-sm="0"
                label-align-sm="left"
                label-size=""
                label-for="filterInput"
                class="ml-auto"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Rechercher par collaborateur..."
                  />
                  <b-input-group-append>
                    <b-button @click="filter = ''" class="btn btn-secondary">
                      Effacer
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
        </b-card-text>
        <div class="">
          <b-table
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            :items="employeesWithAbsenceValidations"
            responsive
            :fields="userTableColumns"
            show-empty
            :busy="busy"
            empty-text="Pas de collaborateur disponible"
            class="position-relative"
            hover
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Chargement...</strong>
              </div>
            </template>

            <template #cell(name)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    badge
                    :badge-variant="data.item.online ? 'success' : 'danger'"
                    badge-offset="-2px"
                    class="badge-minimal"
                    size="32"
                    :src="data.item.picture"
                  />
                </template>
                <span class="font-weight-bold d-block text-nowrap">
                  {{ data.item.firstname + " " + data.item.lastname }}
                </span>
                <small class="text-muted">{{ data.item.email }}</small>
              </b-media>
            </template>

            <template #cell(total_paid_leaves)="data">
              <b-badge class="stat-badge" variant="info">{{
                data.item.total_paid_leaves
              }}</b-badge>
            </template>

            <template #cell(permissions_count)="data">
              <b-badge class="stat-badge" variant="info">{{
                data.item.permissions_count
              }}</b-badge>
            </template>

            <template #cell(absences_count)="data">
              <b-badge class="stat-badge" variant="info">{{
                data.item.absences_count
              }}</b-badge>
            </template>

            <template #cell(leaves_taken)="data">
              <b-badge class="stat-badge" variant="info">{{
                data.item.leaves_taken
              }}</b-badge>
            </template>

            <template #cell(total_paid_leaves_left)="row">
              <b-badge class="stat-badge" variant="warning">{{
                row.item.total_paid_leaves_left
              }}</b-badge>
            </template>

            <template #cell(id)="row">
              <b-btn @click="row.toggleDetails">
                <feather-icon icon="ActivityIcon"></feather-icon>
              </b-btn>
            </template>

            <template #row-details="row">
              <b-card>
                <b-table
                  show-empty
                  empty-text="Aucune donnée"
                  :fields="row_details_fields"
                  :items="row.item.bilan"
                >
                  <template #cell(id)="row">
                    <span>{{
                      row.item.date ? row.item.date : row.item.created_at
                    }}</span>
                  </template>

                  <template #foot="row">
                    <tr>
                      <th>kjdkdn</th>
                    </tr>
                  </template>
                </b-table>
                <br />

                <b-button size="sm" @click="row.toggleDetails">Fermer</b-button>
              </b-card>
            </template>
          </b-table>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-1 ml-1"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import axiosIns from "@/libs/axios";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    FeatherIcon,
  },
  data() {
    return {
      busy: false,
      filter: "",
      filterOn: [],
      currentPage: 1,
      perPage: 10,
      sortBy: "name",
      sortDesc: false,
      totalRows: 1,
      userTableColumns: [
        { key: "name", sortable: true, label: "Nom et prénoms" },
        {
          key: "total_paid_leaves",
          label: "Total acquis (Jours)",
          sortable: true,
        },
        { key: "absences_count", label: "Absences (Jours)", sortable: true },
        {
          key: "permissions_count",
          label: "Permissions (Jours)",
          sortable: true,
        },
        { key: "leaves_taken", label: "Congés pris (Jours)", sortable: true },
        {
          key: "total_paid_leaves_left",
          label: "Total Restant (Jours)",
          sortable: true,
        },
        { key: "id", label: "Actions", sortable: false },
      ],

      row_details_fields: [
        { key: "id", label: "Date" },
        { key: "total_paid_leaves", label: "Solde de congé (Jours)" },
        { key: "absences_count", label: "Absences (Jours)" },
        { key: "permissions_count", label: "Permissions (Jours)" },
        { key: "leaves_taken", label: "Congés pris (Jours)" },
        { key: "total_paid_leaves_left", label: "Reste (Jours)" },
        { key: "other_deduction", label: "Déduction sur salaire (Jours)" },
        { key: "observation", label: "Observation" },
      ],
    };
  },
  computed: {
    ...mapState("enterprise", ["employeesWithAbsenceValidations"]),
  },
  methods: {
    ...mapActions("enterprise", ["getUsersWithAbsencesValidations"]),

    exportToPdf() {
      axiosIns.get("absence-validation/export-pdf").then((r) => {
        window.open(r.data, "_blank");
      });
    },

    onFiltered(employeesWithAbsenceValidations) {
      this.totalRows = employeesWithAbsenceValidations.length;
      this.currentPage = 1;
    },
  },

  mounted() {
    this.busy = true;
    this.getUsersWithAbsencesValidations().then(() => {
      this.busy = false;
      this.totalRows = this.employeesWithAbsenceValidations.length;
    });
  },
};
</script>
